<template>
  <div class="card card-custom">
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Assignments") }}
          <span class="d-block text-muted pt-2 font-size-sm">{{ $t("Secondments management") }}</span>
        </h3>
      </div>
      <div class="card-toolbar">
        <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="min-w-300px mb-0 mr-0" :debounce="300">
        </euro-input>
      </div>
    </div>
    <div class="card-body">
      <datatable ref="datatableManage" :table-props="tableProps" :options.sync="tableOptions"
        :per-page-options="perPageOptions" :filter="searchFilter" :total="total">

        <template #[`cell.country`]="{ item }">
          <div class="font-weight-bolder">
            <country-flag :country-iso="item.country"></country-flag>
          </div>
        </template>

        <template #[`cell.created`]="{ item }">
          <div class="font-weight-bolder text-muted">{{ item.created ? DateService.format(item.created, dateFormat) : ""
            }}</div>
        </template>

        <template #[`cell.date_start`]="{ item }">
          <div class="font-weight-bolder text-muted">{{ item.date_start ? DateService.format(item.date_start,
            dateFormat)
            : "" }}
          </div>
        </template>

        <template #[`cell.date_end`]="{ item }">
          <div class="font-weight-bolder text-muted">{{ item.date_end ? DateService.format(item.date_end, dateFormat) :
            ""
            }}</div>
        </template>

        <template #[`cell.client`]="{ item }">
          <div v-if="item.clients.length" class="font-weight-bold">
            <div v-for="client in item.clients" :key="client.id"><span></span> {{ client.name }}</div>
          </div>
          <div class="text-muted">{{ $t("Company") }}: <span class="font-weight-bolder">{{ item.company_name }}</span>
          </div>
        </template>

        <template #[`cell.employees`]="{ item }">
          <div v-if="item.employees.length" class=" font-weight-bold m-n1">
            <div v-for="employee in item.employees.slice(0, 2)" :key="employee.id" class="m-1">
              <div class="d-flex align-items-center employee-badge">
                <avatar rounded :avatar-image="employee.avatar" avatar-text="" class="flex-shrink-0" size="20px">
                </avatar>
                <span class="text-capitalize ml-1 w-100">{{ employee.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="item.employees.length > 2" class="text-center">
            <b-dropdown :id="item.id" dropright text="Show More" size="sm" variant="text" no-caret>
              <template #button-content>
                <div class="text-right w-100">
                  <span class="employees-dropdown">{{ $t("Show More") }}</span>
                </div>
              </template>
              <b-dropdown-item v-for="employee in item.employees.slice(2)" :key="employee.id" class="m-1">
                <div class="d-flex align-items-center employee-badge">
                  <avatar rounded :avatar-image="employee.avatar" avatar-text="" class="flex-shrink-0" size="20px">
                  </avatar>
                  <span class="ml-1 w-100">{{ employee.name }}</span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>

        <template #[`cell.contractors`]="{ item }">
          <div v-if="item.contractors.length" class="font-weight-bolder">
            <div v-for="contractor in item.contractors" :key="contractor.id"><span></span> {{ contractor.name }}</div>
          </div>
        </template>

        <template #[`cell.days`]="{ item }">
          <div class="font-weight-bolder" style="text-align:right">
            {{ DateService.diff(item.date_end, item.date_start) }}
          </div>
        </template>

        <template #[`cell.status`]="{ item }">
          <secondment-status-button size="sm" block icon-only :status="item.status" :secondment="item" :past="item.past" :started="item.started"
            @update-status="($event) => onSecondmentStatusBtnClick($event, item.id)"></secondment-status-button>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex m-n1">
            <b-button v-b-tooltip.hover :title="$t('View Details')"
              class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2" @click="edit(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Design/Edit.svg" />
              </span>
            </b-button>
          </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import Datatable from "@/view/components/tables/Datatable.vue";
import SecondmentService from "@/core/services/secondment/secondment.service";
import DateService from "@/core/services/date.service";
import SecondmentStatusButton from "@/view/components/buttons/SecondmentStatusButton.vue";
import { mapGetters } from "vuex";
import { updateSecondmentStatus } from "@/core/abstractions/secondment";
import { backendErrorSwal } from "@/core/helpers/swal";
import eventBus from "@/core/services/event/eventBus";
import Avatar from "@/view/components/avatars/Avatar.vue";
import Swal from "sweetalert2";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import { successToast } from '@/core/helpers';

export default {
  components: {
    Datatable,
    CountryFlag,
    SecondmentStatusButton,
    Avatar,
  },

  props: {
    adminView: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DateService: DateService,
      isLoading: false,
      sortBy: "created",
      sortDesc: true,
      fields: [
        { label: this.$t("Country"), key: "country", sortable: true },
        { label: this.$t("Employees"), key: "employees" },
        { label: this.$t("Client"), key: "client" },
        { label: this.$t("Created"), key: "created", sortable: true },
        { label: this.$t("From Date"), key: "date_start", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("To Date"), key: "date_end", sortable: true, class: ["font-weight-bolder"] },
        { label: this.$t("Days"), key: "days" },
        { label: this.$t("Status"), key: "status", sortable: true },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },

      ],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      total: 0,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "isApStaff"]),
    tableProps() {
      return {
        items: this.loadAssignments,
        fields: this.fields,
        filter: this.searchFilter,
      };
    },
  },

  beforeMount() {
    if (this.adminView) {
      this.fields.unshift({ label: this.$t("Company"), key: "company_name", sortable: false });
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Administration") }, { title: this.$t("Assignments") }]);
  },

  methods: {
    edit(item) {
      if (item.company == 1) {
        this.$router.push({
          name: "detail-secondment",
          params: { secondmentId: item.id },
        });
      } else {
        const message = this.$t("Please switch to selected focus in order to edit assignment")
        Swal.fire({
          title: this.$t("Switch to focus") + ": " + item.company_name.toUpperCase(),
          text: message,
          icon: "info",
          confirmButtonText: this.$t("Switch to") + " " + item.company_name,
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.switchToFocus(item);
          }
          else {
            return;
          }
        });
      }
    },

    async switchToFocus(item) {
      eventBus.$emit('change-focus', item.company, { name: "detail-secondment", params: { secondmentId: item.id } })
    },

    refresh() {
      this.$refs.datatableManage.refresh();
    },

    async onSecondmentStatusBtnClick(newStatus, secondmentId) {
      try {
        await updateSecondmentStatus(secondmentId, newStatus, true);
        successToast(this.$t("Assignment status updated!"));
        this.refresh();
      } catch (err) {
        backendErrorSwal(err?.response?.data?.detail ?? this.$t("Failed to update assignment status"), err);
      }
    },
    loadAssignments(ctx, callback) {
      SecondmentService.get({
        fields: 'id,country,created,date_start,date_end,clients,employees,contractors,status,company_name,company,past,started',
        page: ctx.currentPage,
        perPage: ctx.perPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        adminView: true
      }).then(response => {
        this.total = response.data.count;
        callback(response.data.results);
      }).catch(err => {
        callback([]);
        console.error('@loadAssignments', err);
      });
    },
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>